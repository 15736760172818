<ion-split-pane contentId="main-content">
  <ion-menu contentId="main-content" #menu type="overlay">
    <ion-header [translucent]="true">
      <ion-toolbar class="heading">
        <ion-label>
          <h1>Synaps Media</h1>
          <p>{{ (activeUser$ | async)?.email }}</p>
        </ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content [fullscreen]="true">
      <ion-list class="ion-padding-vertical">
        <ion-menu-toggle auto-hide="false">
          <ion-item routerDirection="root" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <ion-thumbnail slot="start">
              <ion-icon name="planet"></ion-icon>
            </ion-thumbnail>
            <ion-label>Dashboard</ion-label>
          </ion-item>

          <ion-item class="site-item" *ngFor="let site of sites$ | async" routerDirection="root" routerLinkActive="active" [routerLink]="['sites', site.serviceName]" [style.--accent-color]="site.info.accent_color">
            <!-- <ion-icon slot="start" name="checkmark-circle" *ngIf="site.status === 'active'" color="success"></ion-icon> -->
            <ion-thumbnail slot="start">
              <img *ngIf="site.status === 'active' && site.info.logo" [src]="site.info.logo">

              <img *ngIf="site.status === 'active' && !site.info.logo && site.info.cover_image" [src]="site.info.cover_image">

              <ion-icon *ngIf="site.status === 'waiting_payment'" name="timer" color="warning"></ion-icon>

              <ion-spinner *ngIf="site.status === 'waiting_deploy'"></ion-spinner>
            </ion-thumbnail>
            <ion-label>
              <h3 *ngIf="site.info.title">{{ site.info.title }}</h3>
              <h3 *ngIf="!(site.info.title)">(Untitled)</h3>
              <p>{{ site.serviceName }}</p>
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
    <ion-footer>
      <ion-list>
        <ion-item routerDirection="root" [href]="portalUrl" lines="none" detail="false">
          <ion-icon slot="start" name="card"></ion-icon>
          <ion-label>Billing</ion-label>
        </ion-item>

        <ion-item routerDirection="root" (click)="menu.close()" [routerLink]="['/settings']" lines="none" routerLinkActive="active"
          detail="false">
          <ion-icon slot="start" name="person"></ion-icon>
          <ion-label>Account Settings</ion-label>
        </ion-item>

        <ion-item button (click)="signOut()" lines="none" detail="false">
          <ion-icon slot="start" name="log-out"></ion-icon>
          <ion-label>Sign out</ion-label>
        </ion-item>
      </ion-list>
    </ion-footer>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-split-pane>

